/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-24 11:30:44
 * @LastEditTime: 2021-09-26 17:36:30
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 样式统一文件
import 'normalize.css/normalize.css'
import '@/styles/index.scss'
// element组件(按需引入)
import './libs'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'FULMcjv037YHTzROZfDkoFj1WTiRGxoS'
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
