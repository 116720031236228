/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-24 11:30:45
 * @LastEditTime: 2021-09-24 11:39:45
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import mainPage from './mainPage'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layout'),
    redirect: '/dashboard',
    children: [
      ...mainPage
    ]
  },
  {
    path: '/PrivacyAgreement',
    name: 'PrivacyAgreement',
    component: () => import('@/views/mobile/PrivacyAgreement'),
    meta: {
      title: '隐私协议'
    }
  },
  {
    path: '/useAgreement',
    name: 'useAgreement',
    component: () => import('@/views/mobile/useAgreement'),
    meta: {
      title: '用户协议'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
