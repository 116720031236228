/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-24 11:30:45
 * @LastEditTime: 2021-12-10 15:57:19
 */
const routes = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard'),
    meta: {
      title: '首页'
    }
  },
  {
    path: 'aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs'),
    meta: {
      title: '关于我们'
    }
  },
  // {
  //   path: 'bussiness',
  //   name: 'bussiness',
  //   component: () => import('@/views/bussiness'),
  //   meta: {
  //     title: '商务合作'
  //   }
  // },
  {
    path: 'contactUs',
    name: 'contactUs',
    component: () => import('@/views/contactUs'),
    meta: {
      title: '联系我们'
    }
  }
]
export default routes
