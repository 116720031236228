/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-05-29 11:04:14
 * @LastEditTime: 2021-09-26 11:28:40
 */

import Vue from 'vue'
import {
  Button,
  Container,
  Header,
  Main,
  Footer,
  Popover,
  Dialog,
  Radio,
  Carousel,
  CarouselItem
} from 'element-ui'

Vue.use(Button).use(Container).use(Header).use(Main).use(Popover).use(Footer).use(Dialog).use(Radio).use(Carousel).use(CarouselItem)
