<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-24 11:30:44
 * @LastEditTime: 2021-09-26 09:42:11
-->
<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '@/styles/index.scss';
  .app{
    @include rect();
  }
</style>
